<template>
  <DeviceReport />
</template>

<script>
export default {
  name: "DeviceReportView",
  components: {
    DeviceReport: () => import('@/components/Device/DeviceReport.vue')
  },
  metaInfo () {
    return {
      title: this.$t('menu.deviceReport')
    }
  }
}
</script>
